import { getAssetPath } from '@stencil/core';

/**
 * This file contains a function to load custom fonts and an array of font objects.
 * We load fonts via JS because they cannot be added from shadow DOM's CSS.
 * Each font object contains the font name, weight, style, display, and source URLs for the different font formats.
 * The `loadFonts` function creates a new `FontFace` object with the given name, source, and configuration,
 * waits for the font to be loaded, and adds it to the document using the `document.fonts.add` method.
 * The `fonts` array is iterated over using `forEach`, and each font object is passed to the `loadFonts` function.
 * This ensures that all the custom fonts are loaded and available for use in the document.
 */
async function loadFonts(name, src, cfg) {
  if (!('FontFace' in globalThis)) {
    return;
  }

  const font = new FontFace(name, src, cfg);
  // wait for font to be loaded
  await font.load();
  // add font to document
  // @ts-expect-error TS may complain about the `add` function, but MDN suggests it's safe
  document.fonts.add?.(font);
}

const fonts = [
  {
    name: 'AudiType',
    weight: 400,
    style: 'normal',
    display: 'swap',
    src: `url('${getAssetPath('assets/fonts/AudiType-Normal.woff')}') format('woff'),
    url('${getAssetPath('assets/fonts/AudiType-Normal.woff2')}') format('woff2'),
    url('${getAssetPath('assets/fonts/AudiType-Normal.ttf')}') format('truetype'),`,
  },

  {
    name: 'AudiTypeBold',
    weight: 400,
    style: 'normal',
    display: 'swap',
    src: `url('${getAssetPath('assets/fonts/AudiType-Bold.woff')}') format('woff'),
    url('${getAssetPath('assets/fonts/AudiType-Bold.woff2')}') format('woff2'),
    url('${getAssetPath('assets/fonts/AudiType-Bold.ttf')}') format('truetype'),`,
  },

  {
    name: 'AudiTypeBoldItalic',
    weight: 400,
    style: 'normal',
    display: 'swap',
    src: `url('${getAssetPath('assets/fonts/AudiType-BoldItalic.woff')}') format('woff'),
    url('${getAssetPath('assets/fonts/AudiType-BoldItalic.woff2')}') format('woff2'),
    url('${getAssetPath('assets/fonts/AudiType-BoldItalic.ttf')}') format('truetype'),`,
  },

  {
    name: 'AudiTypeItalic',
    weight: 400,
    style: 'normal',
    display: 'swap',
    src: `url('${getAssetPath('assets/fonts/AudiType-Italic.woff')}') format('woff'),
    url('${getAssetPath('assets/fonts/AudiType-Italic.woff2')}') format('woff2'),
    url('${getAssetPath('assets/fonts/AudiType-Italic.ttf')}') format('truetype'),`,
  },

  {
    name: 'AudiTypeExtended',
    weight: 400,
    style: 'normal',
    display: 'swap',
    src: `url('${getAssetPath('assets/fonts/AudiType-ExtendedNormal.woff')}') format('woff'),
    url('${getAssetPath('assets/fonts/AudiType-ExtendedNormal.woff2')}') format('woff2'),
    url('${getAssetPath('assets/fonts/AudiType-ExtendedNormal.ttf')}') format('truetype'),`,
  },

  {
    name: 'AudiTypeExtendedBold',
    weight: 400,
    style: 'normal',
    display: 'swap',
    src: `url('${getAssetPath('assets/fonts/AudiType-ExtendedBold.woff')}') format('woff'),
    url('${getAssetPath('assets/fonts/AudiType-ExtendedBold.woff2')}') format('woff2'),
    url('${getAssetPath('assets/fonts/AudiType-ExtendedBold.ttf')}') format('truetype'),`,
  },

  {
    name: 'AudiTypeExtendedBoldItalic',
    weight: 400,
    style: 'normal',
    display: 'swap',
    src: `url('${getAssetPath('assets/fonts/AudiType-ExtendedBoldItalic.woff')}') format('woff'),
    url('${getAssetPath('assets/fonts/AudiType-ExtendedBoldItalic.woff2')}') format('woff2'),
    url('${getAssetPath('assets/fonts/AudiType-ExtendedBoldItalic.ttf')}') format('truetype'),`,
  },

  {
    name: 'AudiTypeExtendedItalic',
    weight: 400,
    style: 'normal',
    display: 'swap',
    src: `url('${getAssetPath('assets/fonts/AudiType-ExtendedItalic.woff')}') format('woff'),
    url('${getAssetPath('assets/fonts/AudiType-ExtendedItalic.woff2')}') format('woff2'),
    url('${getAssetPath('assets/fonts/AudiType-ExtendedItalic.ttf')}') format('truetype'),`,
  },

  {
    name: 'AudiTypeWide',
    weight: 400,
    style: 'normal',
    display: 'swap',
    src: `url('${getAssetPath('assets/fonts/AudiType-WideNormal.woff')}') format('woff'),
    url('${getAssetPath('assets/fonts/AudiType-WideNormal.woff2')}') format('woff2'),
    url('${getAssetPath('assets/fonts/AudiType-WideNormal.ttf')}') format('truetype'),`,
  },

  {
    name: 'AudiTypeWideBold',
    weight: 400,
    style: 'normal',
    display: 'swap',
    src: `url('${getAssetPath('assets/fonts/AudiType-WideBold.woff')}') format('woff'),
    url('${getAssetPath('assets/fonts/AudiType-WideBold.woff2')}') format('woff2'),
    url('${getAssetPath('assets/fonts/AudiType-WideBold.ttf')}') format('truetype'),`,
  },

  {
    name: 'AudiTypeWideLight',
    weight: 300,
    style: 'normal',
    display: 'swap',
    src: `url('${getAssetPath('assets/fonts/AudiType-WideLight.woff')}') format('woff'),
    url('${getAssetPath('assets/fonts/AudiType-WideLight.woff2')}') format('woff2'),
    url('${getAssetPath('assets/fonts/AudiType-WideLight.ttf')}') format('truetype'),`,
  },
];

export function initFonts() {
  fonts.forEach(({ name, src, ...rest }) => loadFonts(name, src, rest));
}
